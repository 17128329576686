import {
  Logo,
  MainPicture,
  Section3_1,
  Section3_2,
  Section4,
  Section5,
} from "../../images/SingleCase/GudJob"

const dataForGudJob = {
  type: "project",
  smart: true,
  classComponent: "Gudjob",
  nextLink: "cases/chernomorsk/",
  prevLink: "cases/iot/",
  data: {
    title: "GudJob",
    subtitle:
      "A strategy game that aims to assess hard skills of job candidates applying for managerial positions. Users are invited to create their virtual companies and lead them to global market dominance while competing against each other.",
    overview: {
      title: "Project overview",
      about: {
        title: "About client",
        content:
          "Young tech startup from Barcelona, Spain providing value through recruitment automation and gamification.",
      },
      challenge: {
        title: "Business challenge",
        content:
          "The decision to pursue this project was triggered in early 2014 as college graduates faced difficulties looking for entry-level jobs. The candidates were facing massive depersonalization of the process and the difficulty of getting meaningful feedback or communication on their applications. Tailored applying for the targeted position resulted in the best case in 3-5% job interviews and only 1 person hired.  Having deeper analyzed existing recruiting process, it became clear that the process was full of challenges and inefficiencies mainly due to its reliance on out-of-date practices. The need for easier talent identification and match became apparent.",
      },
      specific: [
        {
          type: "Platform",
          content: ["Web", "Android"],
          icon: "fa-th-large",
        },
        {
          type: "Technology",
          content: [
            "JavaScript",
            "Ext JS",
            "d3js",
            "Node.JS",
            "ReactJS",
            "SCSS",
            "HTML5",
            "CSS3",
          ],
          icon: "fa-cog",
        },
        {
          type: "Team",
          icon: "fa-users",
          content: [
            {
              type: "React developers",
              count: 2,
            },
            {
              type: "Node.js developer",
              count: 1,
            },
            {
              type: "QA",
              count: 1,
            },
            {
              type: "Designer",
              count: 1,
            },
          ],
        },
        {
          type: "Duration",
          icon: "fa-calendar",
          content: [
            {
              type: "months",
              count: 5,
            },
          ],
        },
        {
          type: "Client",
          content: ["Startup"],
          icon: "fa-user",
        },
      ],
    },
    solution: {
      title: "Solution",
      textFirst: [
        "Learn business concepts and develop your skills while having fun. GUDJOB is a game that enables user to built his career while having fun. It allows creating private business empire, completing for markets and forging alliances.",
        "Once you select the starting role or country, you can choose the strategy that best suits your desired gameplay - to pile up resources or risk everything. On the other hand, the application allows employees to attract and connect with early talent.",
        "The game is a single-page, multi platform, cross-browser application built with React.js, Node.js and MongoDB. Why this combination? React.js allows creating incredibly handy and responsive user interface completely tailored to the customers needs.",
        "Backend side with Node.js is a compiled, powerful, non-blocking application. We have managed to achieve a real-time performance through the use of socket.io library.",
        "Our unique solutions based on a numbers of timers, let us emulate the real life processes and ensure quality interaction with user. MongoDB comes as flexible document-printed data storage to store everything starting from logs up to real-time gaming data.",
        "Through the use of Apache Cordova we compiled the application for multiple platforms such us Android, iOS, Windows and Linux.",
      ],
    },
    delivered: {
      title: "Value delivered",
      list: [
        "created MVP for initial product-market validation",
        "created product and value roadmap",
        "adjusted product through multiple iterations to validate additional hypothesis",
        "created multiple design concepts for the upcoming product versions",
      ],
    },
    images: {
      bgTop: MainPicture,
      logo: Logo,
      section31: Section3_1,
      section32: Section3_2,
      section4: Section4,
      section5: Section5,
    },
  },
  imageSrc: [],
  relatedProjects: [
    {
      link: "/cases/cartovera",
      id: "cartovera",
      title: "Cartovera",
    },
    {
      link: "/cases/karpatska-chaika",
      id: "seagull",
      title: "Carpathian Seagull",
    },
    {
      link: "/cases/wikigrads",
      id: "wikigrads",
      title: "Wikigrads",
    },
    {
      link: "/cases/ilich-realty",
      id: "illich3",
      title: "Ilich Realty",
    },
  ],
  link: "/cases/gud-job",
}

export default dataForGudJob
