import React from "react"

import OverviewComponent from "../OverviewComponent"

export default function Gudjob({ data }) {
  let i = 0
  return [
    <section key={i++}>
      <div
        className="bgBlock bgImage Gudjob"
        style={{ backgroundImage: `url(${data.images.bgTop})` }}
        id="gudjob-header"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12" style={{ height: "100%" }}>
              <div className="titleBlock">
                <div className="iconLogo">
                  <img src={data.images.logo} alt={"Gudjob application icon"} />
                </div>
                <h1 className="Gudjob-subheader">{data.subtitle}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>,

    <OverviewComponent {...data} key={i++} />,

    <section className="Gudjob white-bg image two solution" key={i++}>
      <div className="containerGudjob">
        <div className="container">
          <div className="imagesBlock">
            <img src={data.images.section31} alt="Gudjob application" />
            <img src={data.images.section32} alt="Gudjob application" />
          </div>
        </div>
      </div>
    </section>,

    <section className="white-bg solution" key={i++}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h2 className="noMarginTop">{data.solution.title}</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-7">
            {data.solution.textFirst.map((item, i) => (
              <p className="mb-4" key={i}>{item}</p>
            ))}
          </div>
          <div className="col-md-5" style={{ textAlign: "center" }}>
            <img
              src={data.images.section4}
              alt="Gudjob application"
              className="Gudjob floatImage"
            />
          </div>
        </div>
      </div>
    </section>,

    <section className="Gudjob white-bg image one solution" key={i++}>
      <div className="containerGudjob">
        <div className="container">
          <img src={data.images.section5} alt="Gudjob application" />
        </div>
      </div>
    </section>,

    <section className="white-bg solution last" key={i++}>
      <div className="container">
        <div className="row">
          <div className="col-md-9">
            <h2 className="Gudjob">{data.delivered.title}</h2>
            <ul>
              {data.delivered.list.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>,
  ]
}
